import { toAutoPlayVideoUrl } from 'modules/discover/discoverUtils'
import { MainModal, Icon, MainLink, useWindowSize } from '@moneymade/moneymade-ui'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'

import styles from './PreviewModal.module.scss'

const PreviewModal = ({ isModal, handleSetModal, name, website, url, promoVideo }) => {
  const { screenWidth } = useWindowSize()

  return screenWidth > 961 ? (
    <MainModal
      verticalPosition={screenWidth < 961 ? 'top' : 'center'}
      isOpen={isModal}
      handleClose={() => handleSetModal(false)}
      className={styles.PreviewModal}
    >
      <div className={styles.IframeWrapper}>
        <iframe
          loading="lazy"
          title="PreviewIframe"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          width="100%"
          height="100%"
          src={toAutoPlayVideoUrl(url || promoVideo, true)}
          scrolling="no"
        />
      </div>

      <div className={styles.Footer}>
        <MainLink
          href={website}
          target="_blank"
          rel="noreferrer"
          fontType="main"
          weight="normal"
          color="white"
          layout="outlined"
          size="sm"
          iconAfter={<Icon icon="icon-external-link" size={15} />}
        >
          Visit {name}
        </MainLink>
      </div>
    </MainModal>
  ) : (
    isModal &&
      createPortal(
        <div className={styles.ModalPreview}>
          <div className={styles.Overlay} onClick={() => handleSetModal(false)} aria-hidden />

          <div className={styles.SliderModal}>
            <div className={styles.IframeWrapper}>
              <iframe
                loading="lazy"
                title="PreviewIframe"
                frameBorder="0"
                allow="autoplay; fullscreen"
                width="100%"
                src={toAutoPlayVideoUrl(url || promoVideo, true)}
                className={styles.Iframe}
              />
            </div>
          </div>

          <div className={styles.Footer}>
            <MainLink
              href={website}
              target="_blank"
              rel="noreferrer"
              fontType="main"
              weight="normal"
              color="white"
              layout="outlined"
              size="sm"
              iconAfter={<Icon icon="icon-external-link" size={15} />}
            >
              Visit {name}
            </MainLink>
          </div>

          <button type="button" className={styles.XMark} onClick={() => handleSetModal(false)}>
            <Icon icon="icon-x-mark" size={12} />
          </button>
        </div>,
        document.body
      )
  )
}

PreviewModal.propTypes = {
  isModal: PropTypes.bool,
  handleSetModal: PropTypes.func,
  name: PropTypes.string,
  website: PropTypes.string,
  url: PropTypes.string,
  promoVideo: PropTypes.string
}

PreviewModal.defaultProps = {
  isModal: false,
  handleSetModal: () => {},
  name: '',
  website: '',
  url: '',
  promoVideo: ''
}
export default PreviewModal
