import { isLocalStorage, sortAsc, sortDesc } from 'utils'

// Detect 'liked' comment
export const getBumpsStorage = () => (isLocalStorage() ? JSON.parse(localStorage.getItem('bumpedReviews')) || {} : {})

// Add 'liked' comment status to comments array
export const addBumpsToReviews = (platformId, reviews) => {
  const bumpsStorage = getBumpsStorage()
  const platformsBumps = bumpsStorage[platformId] || {}

  return reviews.map(review => ({ ...review, isBumped: !!platformsBumps[review.id] }))
}
// Like comment action
export const recordReviewBumped = (platformId, reviewId) => {
  const bumpsStorage = getBumpsStorage()

  if (isLocalStorage()) {
    const platformsBumps = bumpsStorage[platformId] || {}

    platformsBumps[reviewId] = 1
    bumpsStorage[platformId] = platformsBumps

    localStorage.setItem('bumpedReviews', JSON.stringify(bumpsStorage))
  }
}

export const isHideMedia = (videos, medias) => {
  let hidePromo = true
  const hideMedia = true

  if (
    (videos !== undefined && Array.isArray(videos) && videos.length) ||
    (medias !== undefined && Array.isArray(medias) && medias.length)
  )
    hidePromo = false

  return hidePromo && hideMedia
}

export const toAutoPlayVideoUrl = (promoVideo, muted) =>
  `${promoVideo?.split('?')[0]}?autoplay=1&mute=${muted ? '0' : '1&muted=1'}` || promoVideo

export const toStopPlayVideoUrl = promoVideo => `${promoVideo?.split('?')[0]}?stopVideo` || promoVideo

export const getRiskRange = risk =>
  risk <= 25 ? 'Low Risk' : risk <= 65 ? 'Moderate Risk' : risk <= 85 ? 'High Risk' : 'Very High Risk'

export const getRoundVal = (val, roundTo = 1) =>
  +val > 0 ? `+${val?.toFixed(roundTo) || 0}` : `${val?.toFixed(roundTo) || 0}`

export const toSortTargetAsc = items =>
  items.sort((a, b) => {
    if (!a.target || !b.target === 1) {
      return 0
    }
    const x = a.target.min === '' ? 100 : parseFloat(a.target.min)
    const y = b.target.min === '' ? 100 : parseFloat(b.target.min)
    return x > y ? 1 : y > x ? -1 : 0
  })

export const toSortTargetDesc = items =>
  items.sort((a, b) => {
    if (!a.target || !b.target === 1) {
      return 0
    }
    const x = a.target.max === '' ? 100 : parseFloat(a.target.max)
    const y = b.target.max === '' ? 100 : parseFloat(b.target.max)
    return x > y ? 1 : y > x ? -1 : 0
  })

export const toSortRatingDesc = items =>
  items.sort((a, b) => {
    const rating = a.averageRating || 0
    const otherRating = b.averageRating || 0

    return rating < otherRating ? 1 : otherRating < rating ? -1 : 0
  })

export const toSortInvestments = (investments, sortType) => {
  switch (sortType) {
    case 'Fees':
      return sortAsc(investments, 'fees')

    case 'Target return (low)':
      return toSortTargetAsc(investments)

    case 'Target return (high)':
      return toSortTargetDesc(investments)

    case 'Min investment (low)':
      return sortAsc(investments, 'minInvestment')

    case 'Min investment (high)':
      return sortDesc(investments, 'minInvestment')

    case 'Rating':
      return toSortRatingDesc(investments)

    default:
      return investments
  }
}

export const getFilterContainsInvestments = (investments, filterKey) =>
  filterKey.length ? investments.filter(investment => investment[filterKey]) : investments

export const toSearchInvestments = (items, searchText) =>
  searchText
    ? items.filter(
        ({ name, industry, description, subIndustry }) =>
          (name && name.toLowerCase().includes(searchText.toLowerCase())) ||
          (description && description.toLowerCase().includes(searchText.toLowerCase())) ||
          (industry && industry.toLowerCase().includes(searchText.toLowerCase())) ||
          (subIndustry && subIndustry.toLowerCase().includes(searchText.toLowerCase()))
      )
    : items

export const getFilteredInvestments = (investments, filters) =>
  filters.reduce((accumulator, { name, item, multiple }) => {
    switch (name) {
      case 'availability': {
        let filteredByAvailability = accumulator

        if (item.includes('Outside US')) filteredByAvailability = filteredByAvailability.filter(({ global }) => global)
        if (item.includes('Non-Accredited Investors'))
          filteredByAvailability = filteredByAvailability.filter(({ investors }) => investors === 'All')
        if (item.includes('Accredited Only'))
          filteredByAvailability = filteredByAvailability.filter(({ investors }) => investors === 'Accredited')

        return filteredByAvailability
      }

      case 'industry': {
        return accumulator?.filter(({ industries }) => industries?.some(industry => item.includes(industry?.name)))
      }

      case 'minInv': {
        return item.length && Number.isInteger(+item)
          ? accumulator.filter(({ minInvestment }) => +item <= minInvestment)
          : accumulator
      }

      case 'maxInv': {
        return item.length && Number.isInteger(+item)
          ? accumulator.filter(({ minInvestment }) => +item >= minInvestment)
          : accumulator
      }

      case 'newRisks': {
        return accumulator.filter(({ newRisks }) => {
          if (multiple) {
            const currentValue = item.map(risk =>
              risk === 'Low risk' ? '1' : risk === 'Medium risk' ? '2' : risk === 'High risk' ? '3' : ''
            )
            return currentValue.includes(newRisks[0])
          }
          const currentValue =
            item === 'Low risk' ? '1' : item === 'Medium risk' ? '2' : item === 'High risk' ? '3' : ''
          return newRisks[0] === currentValue
        })
      }

      default:
        return accumulator.filter(investment => {
          if (Array.isArray(investment[name])) {
            return multiple ? investment[name].some(value => item.includes(value)) : investment[name].includes(item)
          }
          return multiple ? item.includes(investment[name]) : investment[name] === item
        })
    }
  }, investments)
